import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../common/Header';
import Nav from '../common/Nav';
import Hero from '../common/Hero';
import ContentImage from '../common/ContentImage';

const CTS = () => (
  <div>
    <Header />
    <Nav />

    <div className="design-detail-page uk-container height-full-page">
      <Helmet title="Microsoft CTS | Nick Penney" />

      <Hero src="https://nckpnny.imgix.net/design/cts_cover.jpg" crop={[4000, 2000]} cropCenter={['center', 'middle']} />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article" data-uk-lightbox>
          <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m skyblue">
            <h1 className="uk-card-title">Microsoft CTS</h1>
            <p>Lead UX Designer</p>
            <p>July 2014 – July 2015</p>
          </div>

          <h2>Microsoft CTS is a data management engine for political campaigns</h2>

          <p className="text">
            I joined the Microsoft CTS project in the initial planning stages, and served as the lead UX designer until the product was released to campaigns for
            beta testing in July 2015.
            <br />
            <br />
            Working with key stakeholders in US politics, I created wireframes, mockups, and functional prototypes for each facet of campaign data management: data
            ingestion and normalization, data matching and merging, flexible data processing using modular pipelines, and data export.
            <br />
            <br />
            During the development phase, I coordinated a team of 12 developers to ensure a unified visual design system, and a consistent look and feel.
            <br />
            <br />
            I also wrote and animated a promotional explainer video for the 2016 Iowa Caucuses to announce the project, as well as a number of internal videos to demonstrate deliverables.
          </p>

          <div className="uk-grid uk-margin-medium-top">
            {/* Row 1 */}
            <ContentImage src="https://nckpnny.imgix.net/design/cts_landing.png" caption="The CTS landing page" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/cts_matching.png" caption="Importing data and matching against known fields" half border />

            {/* Row 2 */}
            <ContentImage src="https://nckpnny.imgix.net/design/cts_pipeline.png" caption="Setting up a modular data pipeline" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/cts_video.jpg" caption="Screenshot from a CTS PR video" half border />
          </div>
        </article>

        <div className="nav-button-container uk-flex uk-flex-between uk-width-1-1 uk-padding-small uk-margin-large-top">
          <NavLink className="uk-button uk-button-default seafoam" to="/whole">
            prev
          </NavLink>
          <NavLink className="uk-button uk-button-default lavender" to="/weft">
            next
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

export default CTS;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import imagesLoaded from 'imagesloaded';

import Header from './common/Header';
import Nav from './common/Nav';
import MixedTile from './common/MixedTile';

const Art = () => {
  useEffect(() => {
    imagesLoaded(document.querySelector('.mixed-gallery'), (instance) => {
      resizeGalleryItems(true);
    });

    window.addEventListener('resize', onResize, false);

    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, []);

  const resizeGridImage = (item, setImageData = false) => {
    let img, tileSize, imgSize;

    try {
      img = item.getElementsByTagName('img')[0];
      tileSize = item.getBoundingClientRect();
      imgSize = img.getBoundingClientRect();
    } catch (e) {
      console.log('Error getting tile images for resizing');
      return;
    }

    if (setImageData) {
      img.dataset.nativeAspectRatio = imgSize.width / imgSize.height;
    }

    const imgAspectRatio = img.dataset.nativeAspectRatio;

    if (
      Math.abs(imgSize.height - tileSize.height) > 1
      || Math.abs(imgSize.width - tileSize.width) > 1
      || Math.abs(imgSize.width / imgSize.height - imgAspectRatio) > 0.02
      || img.style.maxWidth !== '100%'
    ) {
      const imgWidth = tileSize.height * imgAspectRatio;
      const ratio = imgWidth / tileSize.width;
      img.style.maxWidth = `${ratio * 100.0}%`;
      img.style.width = `${ratio * 100.0}%`;
      // img.style.minHeight = '100%';

      const overflowX = ((ratio - 1.0) * imgSize.width) / 2.0;
      img.style.transform = `translateX(-${overflowX}px)`;
    }
  };

  const resizeGalleryItems = (setImageData = false) => {
    const allItems = document.getElementsByClassName('mixed-tile-content');
    for (let x = 0; x < allItems.length; x++) {
      resizeGridImage(allItems[x], setImageData);
    }
  };

  const onResize = () => {
    resizeGalleryItems();
  };

  return (
    <div>
      <Header />
      <Nav />

      <div className="mixed-page uk-container height-full-page">
        <Helmet title="Nick Penney" />

        <div className="mixed-gallery uk-grid-match uk-margin-medium-top uk-padding-small" data-uk-grid data-uk-lightbox>
          {/* Row */}
          <MixedTile
            caption="Robot arm"
            subtext="3D render. Blender, Substance Painter, Photoshop"
            year="2021"
            src="https://nckpnny.imgix.net/mixed/arm_lg_comp.jpg"
            ukWidths="uk-width-1-1 uk-width-1-1@m"
          />

          {/* Row */}
          <MixedTile
            caption="The box factory"
            subtext="3D render. Blender, Substance Painter, Photoshop"
            year="2021"
            src="https://nckpnny.imgix.net/mixed/factory_post.jpg"
            ukWidths="uk-width-1-1 uk-width-1-1@m"
          />

          {/* Row */}
          <MixedTile
            caption="Pool"
            subtext="3D render, Blender, Substance Designer, Photoshop"
            year="2021"
            src="https://nckpnny.imgix.net/mixed/pool.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          <MixedTile
            caption="The cloud computer"
            subtext="3D render, Blender"
            year="2020"
            src="https://nckpnny.imgix.net/mixed/cloud_computer.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          {/* Row */}
          <MixedTile
            caption="Allevi 3 bioprinter with notebook"
            subtext="3D render, Blender & Corona Renderer"
            year="2019"
            src="https://nckpnny.imgix.net/mixed/a3_notebook.png"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          <MixedTile
            caption="Allevi High-Temp Extruder"
            subtext="3D render, Blender & Corona Renderer"
            year="2020"
            src="https://nckpnny.imgix.net/mixed/hightemp_anodized.png"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          {/* Row */}
          <MixedTile
            caption="Reading light"
            subtext="3D render, Blender"
            year="2020"
            src="https://nckpnny.imgix.net/mixed/reading_light.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          <MixedTile
            caption="Crystal"
            subtext="3D render, Blender"
            year="2020"
            src="https://nckpnny.imgix.net/mixed/crystal.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          {/* Row */}
          <MixedTile
            caption="Thin Film Study #2"
            subtext="3D render, Cinema 4D & Octane"
            year="2015"
            src="https://nckpnny.imgix.net/mixed/hex_01.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          <MixedTile
            caption="Refraction Study #2"
            subtext="3D render, Cinema 4D & Octane"
            year="2016"
            src="https://nckpnny.imgix.net/mixed/hex_02.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          {/* Row */}
          <MixedTile
            caption="American Wealth Inequality"
            subtext={'Gouache,  22" × 30"'}
            year="2015"
            src="https://nckpnny.imgix.net/mixed/wealth_inequality.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
            border
          />

          <MixedTile
            caption="How to Make Toast"
            subtext={'Charcoal and ink,  22" × 30"'}
            year="2016"
            src="https://nckpnny.imgix.net/mixed/toast_diagram.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
            border
          />

          <MixedTile
            caption="Ship Navigation - UI Mockup"
            subtext="3D rendering and UI overlay"
            year="2016"
            src="https://nckpnny.imgix.net/mixed/boat_markers.png"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
            border
          />

          {/* Row */}
          <MixedTile
            caption="Glass Study #1"
            subtext="3D render, Cinema 4D"
            year="2015"
            src="https://nckpnny.imgix.net/mixed/gears.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          {/* Row */}
          <MixedTile
            caption="Environment Study #3"
            subtext={'Acrylic, inkjet photo print, 16" × 20"'}
            year="2013"
            src="https://nckpnny.imgix.net/mixed/env_study_03.jpg"
            ukWidths="uk-width-1-1 uk-width-2-3@m"
          />

          {/* Row */}
          <MixedTile
            caption="Environment Study #1"
            subtext={'Acrylic, inkjet photo print, 20" × 16"'}
            year="2013"
            src="https://nckpnny.imgix.net/mixed/env_study_01.jpg"
            ukWidths="uk-width-1-1 uk-width-1-3@m"
          />

          {/* Row */}
          <MixedTile
            caption="Scale Study #4"
            subtext={'Cut paper, silver halide photo print, 11" × 14"'}
            year="2013"
            src="https://nckpnny.imgix.net/mixed/scale_04.jpg"
            ukWidths="uk-width-1-1 uk-width-1-3@m"
            border
          />

          <MixedTile
            caption="Environment Study #2"
            subtext={'Acrylic paint on inkjet photo print, 16" × 20"'}
            year="2013"
            src="https://nckpnny.imgix.net/mixed/env_study_02.jpg"
            ukWidths="uk-width-1-1 uk-width-2-3@m"
          />

          {/* Row */}
          <MixedTile
            caption="Minopolis"
            subtext={'Computer simulation and projection, 120" × 60"'}
            year="2013"
            src="https://nckpnny.imgix.net/mixed/minopolis.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
          />

          <MixedTile
            caption="Manchester Street Station"
            subtext={'Digital illustration, inkjet print, 16" × 20"'}
            year="2013"
            src="https://nckpnny.imgix.net/mixed/manchester_street_station.jpg"
            ukWidths="uk-width-1-1 uk-width-1-2@m"
            border
          />
        </div>
      </div>
    </div>
  );
};

export default Art;

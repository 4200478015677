import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../common/Header';
import Nav from '../common/Nav';
import Hero from '../common/Hero';
import ContentImage from '../common/ContentImage';

const Weft = () => (
  <div>
    <Header />
    <Nav />

    <div className="design-detail-page uk-container height-full-page">
      <Helmet title="Weft | Nick Penney" />

      <Hero src="https://nckpnny.imgix.net/design/weft_cover_new.jpg" crop={[3000, 1500]} cropCenter={['center', 500]} />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article" data-uk-lightbox>
          <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m lavender">
            <h1 className="uk-card-title">Weft</h1>
            <p>Lead UX Developer</p>
            <p>October 2015 – September 2018</p>
            <p>&nbsp;</p>
            <p>Independent Contractor</p>
            <p>September 2018 – Present</p>
          </div>

          <h2>Weft is a platform for custom woven textiles</h2>

          <p className="text">
            As the lead UX developer for Weft, I helped create a web-based platform to easily design, visualize, and manufacture woven fabrics.
            <br />
            <br />
            Based on user research and mockups I generated, I developed a responsive, mobile-first front end for the platform with React and three.js.
            I programmed the back end CMS and order processing infrastructure in Python, using Django as a DBMS. I contributed to the UX/UI design of the home page, e-commerce site, and supporting documentation, alongside another graphic designer.
            <br />
            <br />
            I designed and developed a Shopify plugin to embed white-labeled Weft product customization into third-party e-commerce sites.
          </p>

          <div className="uk-grid uk-margin-medium-top">
            {/* Row 1 */}
            <ContentImage src="https://nckpnny.imgix.net/design/weft_home.jpg" caption="The Weft home page" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/weft_upload.jpg" caption="Uploading a new design" half border />

            {/* Row 2 */}
            <ContentImage src="https://nckpnny.imgix.net/design/weft_create_1.jpg" caption="Weft Create — the fabric design interface" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/weft_create_2.jpg" caption="Changing a yarn color" half border />

            {/* Row 3 */}
            <ContentImage src="https://nckpnny.imgix.net/design/weft_mobile.png" caption="Weft on mobile" full />
          </div>
        </article>

        <div className="nav-button-container uk-flex uk-flex-between uk-width-1-1 uk-padding-small uk-margin-large-top">
          <NavLink className="uk-button uk-button-default skyblue" to="/cts">
            prev
          </NavLink>
          <NavLink className="uk-button uk-button-default pink" to="/allevi">
            next
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

export default Weft;

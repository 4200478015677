import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../common/Header';
import Nav from '../common/Nav';
import Hero from '../common/Hero';
import ContentImage from '../common/ContentImage';

const Newsmilk = () => (
  <div>
    <Header />
    <Nav />

    <div className="design-detail-page uk-container height-full-page">
      <Helmet title="Newsmilk | Nick Penney" />

      <Hero src="https://nckpnny.imgix.net/design/newsmilk_cover.jpg" crop={[5000, 2000]} cropCenter={['center', 700]} />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article" data-uk-lightbox>
          <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m fiestared">
            <h1 className="uk-card-title">Newsmilk</h1>
            <p>RISD Class Project</p>
            <p>March 2013</p>
          </div>

          <h2>Newsmilk is a news aggregation site</h2>

          <p className="text">
            Newsmilk was a RISD semester project I developed, which used a machine learning algorithm – latent Dirichlet allocation – to analyze the full text of 5,000—10,000 news articles from global sources
            each morning, and compiled a list of the words or phrases with the most coverage.
            <br />
            <br />
            3—5 topics were curated from the list and posted to the site, with at least 3 reliable citations for each story and a link to the original source, if available.
            <br />
            <br />
            I used a machine learning library for Python to analyze the article contents, and the site was built in PHP.
          </p>

          <div className="uk-grid uk-margin-medium-top">
            {/* Row 1 */}
            <ContentImage src="https://nckpnny.imgix.net/design/newsmilk_screen.png" caption="Newsmilk on March 22, 2013" full border />
          </div>
        </article>

        <div className="nav-button-container uk-flex uk-flex-between uk-width-1-1 uk-padding-small uk-margin-large-top">
          <div></div>
          <NavLink className="uk-button uk-button-default tangerine" to="/eco">
            next
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

export default Newsmilk;

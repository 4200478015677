import React from 'react';

const Header = () => (
  <h1 className="header-title">
    <a href="/">
      Nick Penney
    </a>
  </h1>
);

export default Header;

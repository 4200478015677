import React from 'react';
import classnames from 'classnames';

const ContentLink = (props) => {
  const imgLg = `${props.src}?auto=format&bg=FFF&q=85&ch=DPR&w=1600`;

  let captionContent = '';
  if (props.caption) {
    captionContent += `<h3>${props.caption}</h3>`;
  }

  if (props.subtext) {
    captionContent += `<p>${props.subtext}</p>`;
  }

  if (props.download) {
    return (
      <a
        className={classnames({
          'tile uk-padding-small': true,
          'uk-width-1-1': props.full,
          'uk-width-1-1 uk-width-1-2@m': props.half,
          'uk-width-1-2 uk-width-1-4@m': props.quarter,
          stacked: props.stacked,
          hidden: props.hidden,
        })}
        href={props.download}
        data-type="image"
        data-caption={captionContent}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {props.children}
      </a>
    );
  }

  if (props.youtube) {
    return (
      <a
        className={classnames({
          'tile uk-padding-small': true,
          'uk-width-1-1': props.full,
          'uk-width-1-1 uk-width-1-2@m': props.half,
          'uk-width-1-2 uk-width-1-4@m': props.quarter,
          stacked: props.stacked,
          hidden: props.hidden,
        })}
        href={`https://www.youtube.com/watch?v=${props.youtube}?rel=0&showinfo=0&modestbranding=0&color=white&enablejsapi=1`}
        data-uk-lightbox="video-autoplay: true"
        data-caption={captionContent}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {props.children}
      </a>
    );
  }

  return (
    <a
      className={classnames({
        'tile uk-padding-small': true,
        'uk-width-1-1': props.full,
        'uk-width-1-1 uk-width-1-2@m': props.half,
        'uk-width-1-2 uk-width-1-4@m': props.quarter,
        stacked: props.stacked,
        hidden: props.hidden,
      })}
      href={imgLg}
      data-type="image"
      data-caption={captionContent}
    >
      {props.children}
    </a>
  );
};

const ContentImage = (props) => {
  let imgSm = `${props.src}?auto=format&fit=crop&q=75&ch=DPR&w=1200`;

  if (props.hires || props.full) {
    imgSm = `${props.src}?auto=format&fit=crop&q=85&ch=DPR&w=1600`;
  }

  if (props.page) {
    imgSm += `&page=${props.page}`;
  }

  return (
    <ContentLink {...props}>
      <img
        src={imgSm}
        className={classnames({
          border: props.border,
        })}
        alt={props.caption}
      />
      {props.subtext ? (
        <p className="caption">
          {props.caption}
          <br />
          <span className="italic">
            {props.subtext}
          </span>
        </p>
      ) : (
        <>
          {props.caption && (
            <p className="caption">{props.caption}</p>
          )}
        </>
      )}
    </ContentLink>
  );
};

export default ContentImage;

import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './common/Header';
import Nav from './common/Nav';
import DesignTile from './common/DesignTile';

const Design = () => (
  <div>
    <Header />
    <Nav />

    <div className="uk-container height-full-page">
      <Helmet title="Design | Nick Penney" />

      <div className="uk-grid uk-margin-medium-top">
        {/* 1/1 */}
        <DesignTile title="Allevi" href="/allevi" src="https://nckpnny.imgix.net/design/allevi_cover_light.jpg" crop={[4000, 2400]} cropCenter={['center', 0]} full />

        {/* 1/1 */}
        <DesignTile title="Weft" href="/weft" src="https://nckpnny.imgix.net/design/weft_cover_new.jpg" crop={[2500, 1500]} cropCenter={['center', 550]} full />

        {/* 1/2 */}
        <DesignTile title="Microsoft CTS" href="/cts" src="https://nckpnny.imgix.net/design/cts_cover.jpg" half />

        <DesignTile title="Whole" href="/whole" src="https://nckpnny.imgix.net/design/whole_cover.jpg" half />

        {/* 1/2 */}
        <DesignTile title="The Economist Eco" href="/eco" src="https://nckpnny.imgix.net/design/eco_cover.jpg" crop={[2000, 1600]} half />

        <DesignTile title="Newsmilk" href="/newsmilk" src="https://nckpnny.imgix.net/design/newsmilk_cover.jpg" crop={[2800, 3000]} cropCenter={['center', 300]} half />
      </div>
    </div>
  </div>
);

export default Design;

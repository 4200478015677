import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../common/Header';
import Nav from '../common/Nav';
import Hero from '../common/Hero';
import ContentImage from '../common/ContentImage';

const Eco = () => (
  <div>
    <Header />
    <Nav />

    <div className="design-detail-page uk-container height-full-page">
      <Helmet title="The Economist Eco | Nick Penney" />

      <Hero src="https://nckpnny.imgix.net/design/eco_cover.jpg" crop={[4000, 2000]} cropCenter={['center', 'middle']} />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article" data-uk-lightbox>
          <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m tangerine">
            <h1 className="uk-card-title">The Economist Eco</h1>
            <p>Media Lab Resident</p>
            <p>July 2013</p>
          </div>

          <h2>The Economist Eco is a daily news briefing from The Economist</h2>

          <p className="text">
            While employed as a Media Lab Resident for The Economist, I was tasked with dreaming up products that could help the publication expand into new markets.
            <br />
            <br />
            The Economist Eco is one of the ideas I presented, which is a daily, short-form news briefing app, with content written by The Economist editorial staff.
            Viewswire was another project with a similar goal, but was designed specifically for readers of The Economist Intelligence Unit –
            an in-house research service which provides for-hire forecasting and analysis.
            <br />
            <br />
            The Economist launched a daily mobile news briefing similar to Eco in November 2014, called Espresso.
          </p>

          <div className="uk-grid uk-margin-medium-top">
            {/* Row 1 */}
            <ContentImage src="https://nckpnny.imgix.net/design/eco_screen.jpg" caption="The Economist Eco" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/eco_viewswire.jpg" caption="The Economist Viewswire" half border />
          </div>
        </article>

        <div className="nav-button-container uk-flex uk-flex-between uk-width-1-1 uk-padding-small uk-margin-large-top">
          <NavLink className="uk-button uk-button-default fiestared" to="/newsmilk">
            prev
          </NavLink>
          <NavLink className="uk-button uk-button-default seafoam" to="/whole">
            next
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

export default Eco;

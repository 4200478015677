import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../common/Header';
import Nav from '../common/Nav';
import Hero from '../common/Hero';
import ContentImage from '../common/ContentImage';

const Allevi = () => (
  <div>
    <Header />
    <Nav />

    <div className="design-detail-page uk-container height-full-page">
      <Helmet title="Allevi | Nick Penney" />

      <Hero src="https://nckpnny.imgix.net/design/allevi_cover_light.jpg" crop={[4000, 2000]} cropCenter={['center', 0]} />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article" data-uk-lightbox>
          <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m pink">
            <h1 className="uk-card-title">Allevi</h1>
            <p>Director of UX, Software Design</p>
            <p>September 2018 – Present</p>
          </div>

          <h2>Allevi makes desktop 3D bioprinters</h2>

          <p className="text">
            As the Director of User Experience and Software Design at Allevi, I designed and developed the user interface for a line of IoT-enabled desktop 3D bioprinters – Allevi Bioprint Pro.
            <br />
            <br />
            I fully redesigned the company’s web presence and e-commerce platform, leading to a 350% increase in monthly natural search impressions.
            <br />
            <br />
            Working across teams, I developed and nurtured a consistent brand and design language for the company, and was responsible for creating our product renders, designing product packaging and labels, and producing marketing videos.
          </p>

          <div className="uk-grid uk-margin-medium-top">
            {/* Row 1 */}
            <ContentImage src="https://nckpnny.imgix.net/design/bioprint_pro_cover_image.jpg" youtube="wh9vaPBz2bM" caption="Allevi Bioprint Pro demo video" full />

            {/* Row 2 */}
            <ContentImage src="https://nckpnny.imgix.net/design/allevi_homepage.png" caption="The Allevi homepage" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/a1_page_2.png" caption="Allevi 1 product page" half border />

            {/* Row 3 */}
            <ContentImage src="https://nckpnny.imgix.net/design/a3_front_notebook.png" caption="Allevi 3 product render" full />

            {/* Row 4 */}
            <ContentImage src="https://nckpnny.imgix.net/design/allevi_sw_3.png" caption="Bioprint Pro 3D print preview" full border />
          </div>
        </article>

        <div className="nav-button-container uk-flex uk-flex-between uk-width-1-1 uk-padding-small uk-margin-large-top">
          <NavLink className="uk-button uk-button-default lavender" to="/weft">
            prev
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

export default Allevi;

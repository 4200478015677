import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Design from './components/Design';
import Video from './components/Video';
import Art from './components/Art';
import About from './components/About';

import Allevi from './components/design/Allevi';
import CTS from './components/design/CTS';
import Eco from './components/design/Eco';
import Newsmilk from './components/design/Newsmilk';
import Weft from './components/design/Weft';
import Whole from './components/design/Whole';

import NotFound from './components/NotFound';

const routes = [
  {
    path: '/',
    component: Art,
    exact: true,
  },
  {
    path: '/allevi',
    component: Allevi,
  },
  {
    path: '/design',
    component: Design,
  },
  {
    path: '/video',
    component: Video,
  },
  {
    path: '/motion',
    component: Video,
  },
  {
    path: '/mixed',
    component: Art,
  },
  {
    path: '/art',
    component: Art,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/cts',
    component: CTS,
  },
  {
    path: '/eco',
    component: Eco,
  },
  {
    path: '/newsmilk',
    component: Newsmilk,
  },
  {
    path: '/weft',
    component: Weft,
  },
  {
    path: '/whole',
    component: Whole,
  },
];

const App = () => {
  const browserHistory = createBrowserHistory();

  useEffect(() => {
    // Scroll to top on page navigation
    const unlisten = browserHistory.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, [browserHistory]);

  return (
    <BrowserRouter history={browserHistory}>
      <div className="body-container uk-height-viewport">
        <Helmet title="Nick Penney" />

        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route component={NotFound} />
        </Switch>

        <footer>
          <div className="text-center">© Nick Penney 2021</div>
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';
import classnames from 'classnames';

const MixedTile = props => {
  let imageSrc = '';
  let imageLg = '';

  if (props.src) {
    imageSrc = `${props.src}?auto=format&q=75&ch=DPR&w=1200`;
    imageLg = `${props.src}?auto=format&q=75&ch=DPR&fit=max&w=2000&h=2000`;
  }

  let captionContent = '';
  if (props.caption) captionContent = `<h3>${props.caption}</h3>`;
  if (props.subtext) captionContent += `<p>${props.subtext}</p>`;
  if (props.year) captionContent += `<p className={'year'}>${props.year}</p>`;

  return (
    <div
      className={classnames({
        'mixed-tile': true,
        border: props.border,
        [`${props.ukWidths}`]: props.ukWidths !== undefined,
      })}
    >
      <a className="mixed-tile-content" href={imageLg} data-caption={captionContent} data-type="image">
        <img className="mixed-tile-image" src={imageSrc} alt={props.caption} />
      </a>
    </div>
  );
};

export default MixedTile;

import React from 'react';

const Hero = props => {
  let imageSrc = 'https://picsum.photos/1200/800/?image=885';

  if (props.src) {
    imageSrc = `${props.src}?auto=format&q=85&ch=DPR`;

    imageSrc += '&w=2000';
    imageSrc += `&rect=${props.cropCenter ? props.cropCenter[0] : 'center'},`;
    imageSrc += `${props.cropCenter ? props.cropCenter[1] : 'middle'},`;
    imageSrc += `${props.crop ? props.crop[0] : 2460},`;
    imageSrc += `${props.crop ? props.crop[1] : 2460}`;
  }

  const heroStyle = {
    backgroundImage: `url(${imageSrc})`,
  };

  return (
    <div className="hero" style={heroStyle}>
      {props.content}
    </div>
  );
};

export default Hero;

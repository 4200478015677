import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../common/Header';
import Nav from '../common/Nav';
import Hero from '../common/Hero';
import ContentImage from '../common/ContentImage';

const Whole = () => (
  <div>
    <Header />
    <Nav />

    <div className="design-detail-page uk-container height-full-page">
      <Helmet title="Whole | Nick Penney" />

      <Hero src="https://nckpnny.imgix.net/design/whole_cover.jpg" crop={[4000, 2000]} cropCenter={['center', 'middle']} />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article" data-uk-lightbox>
          <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m seafoam">
            <h1 className="uk-card-title">Whole</h1>
            <p>RISD MFA Thesis Project</p>
            <p>May 2014</p>
          </div>

          <h2>Whole is an interactive video game installation</h2>

          <p className="text">
            Whole is a video game that I created in 2013 and 2014 for my MFA thesis project at RISD, which ran continuously behind a gallery wall at the Rhode Island Convention
            Center May 16—31, 2014.
            <br />
            <br />
            The game served as an accompaniment to my written thesis, and was a &lsquo;video game short story&rsquo;: a proof-of-concept demonstrating the
            narrative and experiential potential of videos games as art. The visuals are a mixture of hand-drawn illustrations and collaged photography.
            <br />
            <br />
            Whole runs on a custom-built 2D engine I developed in C++, using Qt for window management and I/O, and OpenGL for rendering and compositing. I also wrote a
            fully-featured level editor for the game.
          </p>

          <div className="uk-grid uk-margin-medium-top">
            {/* Row 1 */}
            <ContentImage src="https://nckpnny.imgix.net/design/whole_detail.jpg" caption="Installation detail" half />

            <ContentImage src="https://nckpnny.imgix.net/design/whole_intro.jpg" caption="Screenshot from the game" half />

            {/* Row 2 */}
            <ContentImage src="https://nckpnny.imgix.net/design/whole_screen.jpg" caption="Navigating the game world" half border />

            <ContentImage src="https://nckpnny.imgix.net/design/doublestick_editor.jpg" caption="Custom level editor for Whole" half border />
          </div>
        </article>

        <div className="nav-button-container uk-flex uk-flex-between uk-width-1-1 uk-padding-small uk-margin-large-top">
          <NavLink className="uk-button uk-button-default tangerine" to="/eco">
            prev
          </NavLink>
          <NavLink className="uk-button uk-button-default skyblue" to="/cts">
            next
          </NavLink>
        </div>
      </div>
    </div>
  </div>
);

export default Whole;

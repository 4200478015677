import React from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import Header from './common/Header';
import Nav from './common/Nav';

const VideoTile = props => {
  const getVideoURL = () => {
    if (props.youtube) {
      return `https://www.youtube.com/watch?v=${props.youtube}?rel=0&showinfo=0&modestbranding=0&color=white&enablejsapi=1`;
    }

    if (props.vimeo) {
      return `https://vimeo.com/${props.vimeo}`;
    }

    return 'none';
  };

  const getThumbnailURL = () => {
    if (props.youtube) {
      return `https://img.youtube.com/vi/${props.youtube}/maxresdefault.jpg`;
    }

    if (props.vimeo) {
      return `https://vimeo.com/${props.vimeo}`;
    }

    return 'none';
  };

  const tileStyle = {
    backgroundImage: `url(${getThumbnailURL()})`,
  };

  let captionContent = `<h3>${props.caption}</h3>`;
  if (props.subtext) {
    captionContent += `<p>${props.subtext}</p>`;
  }

  if (props.year) {
    captionContent += `<p className={'year'}>${props.year}</p>`;
  }

  return (
    <div
      uk-lightbox
      className={classnames({
        'tile video uk-width-1-1 uk-padding-small height-xlarge': true,
        'height-xlarge': !props.half,
        'uk-width-1-2@m height-large': props.half,
      })}
    >
      <a className="tile-content uk-flex uk-flex-center uk-flex-middle" href={getVideoURL()} data-caption={captionContent} style={tileStyle}>
        <div className="tile-card">{props.caption}</div>
      </a>
    </div>
  );
};

const Video = () => (
  <div>
    <Header />
    <Nav />

    <div className="uk-container height-full-page">
      <Helmet title="Video | Nick Penney" />

      <div className="uk-grid uk-margin-medium-top" data-uk-lightbox="video-autoplay: true">
        <VideoTile
          caption="Nick Penney – 2020 Demo Reel"
          subtext="A selection of my work for Microsoft, Starbucks, 63 Magazine, Allevi, and The Seattle Symphony, as well as a number of independent projects"
          year="2020"
          youtube="f8dFxVFT_kk"
        />

        <VideoTile
          caption="Microsoft – CTS Core"
          subtext={"A promotional video for CTS Core: Microsoft's offering for political campaign data management"}
          year="2016"
          youtube="ow_BSGkIXoE"
          half
        />

        <VideoTile
          caption="Microsoft – Trusted Data Platforms"
          subtext="An informational video for Trusted Data Platforms: an idea Microsoft is exploring to help solve open data problems"
          year="2016"
          youtube="j_85nZeg8ok"
          half
        />

        <VideoTile
          caption="Microsoft – How to Break Up With Your Desk"
          subtext="A promotional video for Microsoft Office 365. CD POP Agency"
          year="2017"
          youtube="YxPn1EvLeK4"
          half
        />

        <VideoTile
          caption="The Seattle Symphony – Tuning Up! George Gershwin"
          subtext={"One of four videos created to promote the Seattle Symphony's Summer 2016 concert series"}
          year="2016"
          youtube="hxuGXQyJVeg"
          half
        />

        <VideoTile
          caption="The Seattle Symphony – Tuning Up! Aaron Copland"
          subtext={"One of four videos created to promote the Seattle Symphony's Summer 2016 concert series"}
          year="2016"
          youtube="cHBJAGvjEbQ"
          half
        />

        <VideoTile
          caption="The Seattle Symphony – Shostakovich Festival"
          subtext={"A promotional video for the Seattle Symphony's Shostakovich Festival"}
          year="2016"
          youtube="OIAQRtuGUjU"
          half
        />

        <VideoTile
          caption="63 Magazine – Launch Promo Video"
          subtext="A promotional video for 63 Magazine, a magazine for progressive political organizers"
          year="2016"
          youtube="bv_P5oABhPk"
          half
        />

        <VideoTile
          caption="Microsoft – 2016 Iowa Caucuses"
          subtext="Created for Microsoft, this video served as an internal pitch video and external press release for the 2016 Iowa Caucuses"
          year="2015"
          youtube="OyE7aD5h-uE"
          half
        />
      </div>
    </div>
  </div>
);

export default Video;

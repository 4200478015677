import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './common/Header';
import Nav from './common/Nav';

const NotFound = () => (
  <div>
    <Header />
    <Nav />

    <div className="uk-container height-full-page uk-flex uk-flex-column uk-flex-center uk-flex-middle">
      <Helmet title="404 | Nick Penney" />
      <p className="not-found">Bummer, classic 404. Nothing&apos;s here.</p>
    </div>
  </div>
);

export default NotFound;

import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => (
  <nav className="uk-navbar-container" data-uk-navbar="delay-hide: 0; duration: 0">
    <div className="uk-navbar-center">
      <ul className="uk-navbar-nav">
        <li>
          <NavLink
            exact
            to="/"
            className="deeporange"
            isActive={(match, location) => ['/', '/art', '/mixed'].indexOf(location.pathname) > -1}
          >
            2D & 3D Art
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/video"
            className="amber"
            isActive={(match, location) => ['/video', '/motion'].indexOf(location.pathname) > -1}
          >
            Motion & Video
          </NavLink>
        </li>
        <li>
          <NavLink to="/design" className="cyan">
            Design
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="purple">
            About
          </NavLink>
        </li>
      </ul>
    </div>
  </nav>
);

export default Nav;

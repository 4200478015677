import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const DesignTile = props => {
  let imageSrc = '';

  if (props.src) {
    imageSrc = `${props.src}?auto=format&q=90&ch=DPR`;

    if (props.full) {
      imageSrc += '&w=2000';
    } else if (props.half) {
      imageSrc += '&w=1200';
    }

    imageSrc += `&rect=${props.cropCenter ? props.cropCenter[0] : 'center'},`;
    imageSrc += `${props.cropCenter ? props.cropCenter[1] : 'middle'},`;
    imageSrc += `${props.crop ? props.crop[0] : 2460},`;
    imageSrc += `${props.crop ? props.crop[1] : 2460}`;
  }

  const tileStyle = {
    backgroundImage: `url(${imageSrc})`,
  };

  let captionContent = '';
  if (props.caption) captionContent = `<h3>${props.caption}</h3>`;
  if (props.subtext) captionContent += `<p>${props.subtext}</p>`;

  return (
    <div
      className={classnames({
        'tile uk-padding-small': true,
        'uk-width-1-1 height-xlarge': props.full,
        'uk-width-1-1 uk-width-1-2@m height-xlarge': props.half,
        'uk-width-1-2 uk-width-1-4@m height-medium': props.quarter,
        'uk-width-1-3 uk-width-1-6@m height-small': props.sixth,
      })}
    >
      <Link className="tile-content uk-flex uk-flex-center uk-flex-middle" style={tileStyle} to={props.href ? props.href : props.src} data-caption={captionContent}>
        <div
          className={classnames({
            'tile-card': true,
            half: props.half,
            quarter: props.quarter,
            sixth: props.sixth,
          })}
        >
          {props.title}
        </div>
      </Link>
    </div>
  );
};

export default DesignTile;

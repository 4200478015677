import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './common/Header';
import Nav from './common/Nav';
import ContentImage from './common/ContentImage';

const About = () => (
  <div>
    <Header />
    <Nav />

    <div className="about-page uk-container height-full-page">
      <Helmet title="About | Nick Penney" />

      <div className="design-content uk-margin-medium-top uk-margin-medium-bottom">
        <article className="uk-article">
          <h2>My name is Nick Penney</h2>

          <p className="text center">
            This site contains highlights of my past work, and my resume is available for download below.
            <br />
            <br />
          </p>

          <div className="uk-grid">
            <ContentImage
              src="https://nckpnny.imgix.net/media/Nick_Penney_Resume_10_21b.pdf"
              download={`${process.env.PUBLIC_URL}/media/Nick_Penney_Resume.pdf`}
              nocaption
              page="1"
              full
              hires
              stacked
              border
            />

            <ContentImage
              src="https://nckpnny.imgix.net/media/Nick_Penney_Resume_10_21b.pdf"
              download={`${process.env.PUBLIC_URL}/media/Nick_Penney_Resume.pdf`}
              caption="My resume — click to download PDF"
              page="2"
              full
              hires
              stacked
              border
            />
          </div>
        </article>
      </div>
    </div>
  </div>
);

export default About;
